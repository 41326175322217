<template>
  <div class= "content">
    <div id="vr-viewer"></div>
  </div>
</template>

<script>
 import { Viewer } from "@photo-sphere-viewer/core";
 import "@photo-sphere-viewer/core/index.css";
 import  { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
 import '@photo-sphere-viewer/markers-plugin/index.css';
 import axios from 'axios'
 import wx from 'weixin-js-sdk'

export default {
  data(){
    return {
      viewer:null,
      markersPlugin:null,
      cur_sceneId:0,
      autorotate:null,
      title:'',
      curData:{},
      markerIds:[]
    }
  },
  mounted(){
    // 获取参数，根据参数加载不同的场景
    const queryString = window.location.href
    const regex = new RegExp('[?&]' + 'scene_id' + '(?:[=]([^&#]*))?', 'i')
    const p = regex.exec(queryString)
    
    const param = p ? decodeURIComponent(p[1]) : 0 // 输出URL参数p的值
    if(param == '' || param == 0)
    {
      return;        
    }
    this.cur_sceneId = param
    this.loadConfig()
  },
  methods:{
    loadConfig(){
      axios.post('https://yxy.sxc-tech.com/api/vr/showSceneBySceneId', {
        scene_id:this.cur_sceneId
      }).then(response =>{
          document.title = response.data.data.title
          this.curData = response.data.data
          this.createViewer()
          this.loadData(response.data.data.default_scene)
      })
    },
    setViewerOptions()
    {
        this.viewer.setOptions({
          defaultZoomLvl:this.curData.defaultZoomLvl,
          minFov:this.curData.minFov,
          maxFov:this.curData.maxFov,
        })
    },
    loadData(_scene_id)
    {
      if(_scene_id == null) return;
      axios.post('https://yxy.sxc-tech.com/api/vr/getImageBysceneId', {
      scene_id: _scene_id
      }).then((response)=>{
        if(response.data.code == 1)
        {
          this.curData = response.data.data
          this.viewer.setPanorama(response.data.data.image);
        }
      })
    },
    addMarkers(){
      for(const item of this.curData.markers)
      {
        this.markersPlugin.addMarker(item)
        this.markerIds.push(item.id)
      }
      this.markersPlugin.showAllTooltips();
    },
    createViewer(){
      this.viewer = new Viewer({
        container: document.querySelector('#vr-viewer'),
        panorama: '',
        size:{
          width:"100%",
          height:"100%",
        },
        navbar:false,
        loadingTxt:'加载中...',
        defaultZoomLvl:0,
        plugins:[
          [MarkersPlugin, {
            markers: [],
          }],
        ]
      }),
      this.markersPlugin = this.viewer.getPlugin(MarkersPlugin);
      this.markersPlugin.addEventListener('select-marker', ({ marker }) => {
        if(marker.config.isVirtual == true){
          if(this.viewer)
          {
            this.backToWinMin()
          }
        }else{//进入AR虚拟游
          const s_id = marker.config.sceneId
          this.markersPlugin.clearMarkers()
          this.destroyViewer()
          this.createViewer()
          this.loadData(s_id)
        }
        
      });
      this.viewer.addEventListener('ready', () => {
        this.setViewerOptions()
        this.addMarkers()
        }, { once: true });
    },
    destroyViewer() {
        if (this.viewer) 
        {
          try{
            this.viewer.destroy();
            this.viewer = null;
            this.markersPlugin = null;
          }
          catch(e){
            const viewer = document.querySelector('#vr-viewer')
            viewer.removeChild(viewer.childNodes[0])
          }
        }
        // this.loadData()
    },
    backToWinMin(){
      wx.config({
        debug: false,
        appId: 'wxd86492b09701d916',
        timestamp: '',
        nonceStr: '',
        signature: '',
        jsApiList: [] // 需要使用的 API 列表
      })
      wx.miniProgram.navigateTo({
        url:'/ARPages/pages/Ar/index?type=0'
      })
    }
  }
}
</script>
<style>
.content{
    width: 100vw;
    height: 100vh;
    margin-left: -8px;
    margin-top: -8px;
}
#vr-viewer{
  width:100vw;
  height: 100vh;
}
</style>
